import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col items-center">
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={abductionIllustration}
        />
        <div className="flex mx-auto"></div>
        <h2 className="text-2xl font-bold my-8 p-3">
          Nothing to see here.
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
